import {
  AuthContext,
  IAuthContext,
  IAuthObject,
  UserPayload,
} from "@/context/AuthContext";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";

const _safeReadFromLocalStorage = (key: string) => {
  try {
    const stored = localStorage.getItem(key);
    if (!stored) return null;
    return stored;
  } catch (e) {
    return null;
  }
};

const _safeJSONParse = (str: string | null) => {
  if (str === null) return null;

  try {
    const parsed = JSON.parse(str);
    if (!parsed) return null;

    return parsed;
  } catch (e) {
    return null;
  }
};

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [auth, setAuth] = useState<IAuthObject>({
    token: _safeReadFromLocalStorage("token"),
    isLoggedIn: true,
    currentUser: _safeJSONParse(
      _safeReadFromLocalStorage("currentUser"),
    ) as UserPayload,
  });

  const updateCurrentUserDetail = (
    detail: keyof UserPayload,
    value: string,
  ) => {
    setAuth(
      (prev) =>
        ({
          ...prev,
          currentUser: { ...prev.currentUser, [detail]: value },
        }) as IAuthObject,
    );
  };

  const logIn = (token: string, currentUser: UserPayload) => {
    setAuth({
      token,
      isLoggedIn: true,
      currentUser,
    });
  };

  const logOut = () => {
    setAuth({
      token: null,
      isLoggedIn: false,
      currentUser: null,
    });
  };

  const updateToken = (token: string) => {
    setAuth((prev) => {
      return { ...prev, token };
    });
  };

  useEffect(() => {
    const checkToken = async () => {
      if (!auth.token) return;

      const res = await fetch(`${process.env.SERVER_API_URL}/validate-token`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (res.status === 200) {
        setAuth((prev) => {
          return { ...prev, isLoggedIn: true };
        });
      }
    };

    if (!auth.token) {
      setAuth((prev) => ({ ...prev, isLoggedIn: false }));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("token", auth.token || "");
    localStorage.setItem("currentUser", JSON.stringify(auth.currentUser) || "");
  }, [auth]);

  const value: IAuthContext = useMemo(
    () => ({ auth, logIn, logOut, updateToken, updateCurrentUserDetail }),
    [auth],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
