"use client";
import { FC, useState } from "react";
import Modal, { ModalProps } from "../common/modal";
import LoginForm from "./login-form";
import RegisterForm from "./register-form";
import { useRouter } from "next/navigation";
import RecoveryForm from "./recovery-form";

interface SignInModalProps extends Pick<ModalProps, "handleClose"> {}

export interface FormProps {
  changeForm: (form: "login" | "register" | "recovery") => void;
  onSuccess: () => void;
}

const SignInModal: FC<SignInModalProps> = ({ handleClose }) => {
  const router = useRouter();
  const [formToDisplay, setFormToDisplay] = useState<
    "login" | "register" | "recovery"
  >("login");

  const changeForm = (form: "login" | "register" | "recovery") => {
    setFormToDisplay(form);
  };

  const onLoginSubmit = () => {
    handleClose();
  };

  const onRegisterSubmit = () => {
    handleClose();
    router.push("/profile");
  };

  const onRecoverySubmit = () => {
    handleClose();
    alert("Recovery email sent");
  };

  return (
    <Modal handleClose={handleClose} size="small">
      {formToDisplay === "login" ? (
        <LoginForm changeForm={changeForm} onSuccess={onLoginSubmit} />
      ) : formToDisplay === "register" ? (
        <RegisterForm changeForm={changeForm} onSuccess={onRegisterSubmit} />
      ) : formToDisplay === "recovery" ? (
        <RecoveryForm changeForm={changeForm} onSuccess={onRecoverySubmit} />
      ) : (
        <div></div>
      )}
    </Modal>
  );
};

export default SignInModal;
