import { FC, SVGAttributes } from "react";

export const HamburgerMenu: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="5" y="5" width="20" height="4" rx="2" />
    <rect x="5" y="13" width="20" height="4" rx="2" />
    <rect x="5" y="21" width="20" height="4" rx="2" />
  </svg>
);
