export default function s3Loader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  return `https://s3.amazonaws.com/v2.dev1.gbcigroup.com${src}`;
}
