import { useEffect, useState } from "react";

const BREAKPOINT = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

function useBreakpoint() {
  const [sm, setSm] = useState(false);
  const [md, setMd] = useState(false);
  const [lg, setLg] = useState(false);
  const [xl, setXl] = useState(false);
  const [xxl, setXxl] = useState(false);

  function handleResize(ev: UIEvent) {
    const width = (ev.target as Window).innerWidth;

    if (width < BREAKPOINT.sm) {
      setSm(true);
      setMd(false);
      setLg(false);
      setXl(false);
      setXxl(false);
    } else if (width < BREAKPOINT.md) {
      setSm(false);
      setMd(true);
      setLg(false);
      setXl(false);
      setXxl(false);
    } else if (width < BREAKPOINT.lg) {
      setSm(false);
      setMd(false);
      setLg(true);
      setXl(false);
      setXxl(false);
    } else if (width < BREAKPOINT.xl) {
      setSm(false);
      setMd(false);
      setLg(false);
      setXl(true);
      setXxl(false);
    } else {
      setSm(false);
      setMd(false);
      setLg(false);
      setXl(false);
      setXxl(true);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.dispatchEvent(new Event("resize"));

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { sm, md, lg, xl, xxl };
}

export default useBreakpoint;
