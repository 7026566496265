import { ButtonHTMLAttributes, FC, ReactNode } from "react";

interface ButtonProps {
  children: ReactNode;
  size?: "small" | "medium" | "large";
}

const Button: FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  size = "medium",
  ...props
}) => {
  return (
    <button
      {...props}
      className="bg-gradient-to-br from-gbci-primary to-gbci-accent hover:brightness-75 transition-all duration-150 p-4 text-white font-bold rounded-md shadow-md"
    >
      <p
        className={`drop-shadow-md ${size == "large" ? "text-xl" : size == "small" ? "text-sm" : "text-base"}`}
      >
        {children}
      </p>
    </button>
  );
};

export default Button;
