import { FC, useContext, useState } from "react";
import { FormProps } from ".";
import Button from "../common/button";
import { SERVER_API_URL } from "@/constants/server";
import { AuthContext, UserPayload } from "@/context/AuthContext";

enum Alerts {
  NO_ALERT,
  USER_ALREADY_EXISTS,
  SERVER_ERROR,
}

const RegisterForm: FC<FormProps> = ({ changeForm, onSuccess }) => {
  const { logIn } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [showAlert, setShowAlert] = useState(Alerts.NO_ALERT);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.id]: "" }); // Remove error on type
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = {
      name: "",
      email: "",
      password: "",
      confirm_password: "",
    };

    let register_errors = 0;

    if (!formData.name) {
      errors.name = "Please enter your name.";
      register_errors = register_errors + 1;
    }

    if (!formData.email) {
      errors.email = "Please enter your email.";
      register_errors = register_errors + 1;
    } else {
      if (!formData.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        errors.email = "Please enter a valid email address";
        register_errors = register_errors + 1;
      }
    }

    if (!formData.password) {
      errors.password = "Please enter your password.";
      register_errors = register_errors + 1;
    }
    if (!formData.confirm_password) {
      errors.confirm_password = "Please confirm password.";
      register_errors = register_errors + 1;
    } else if (formData.confirm_password != formData.password) {
      errors.confirm_password = "Password is not the same";
      register_errors = register_errors + 1;
    }

    if (register_errors != 0) {
      setFormErrors(errors);
      return;
    }

    const credentials = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
    };

    const registerUser = async () => {
      try {
        const res = await fetch(`${SERVER_API_URL}/auth/register`, {
          body: JSON.stringify(credentials),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        if (res.status >= 200 && res.status <= 299) {
          const data = await res.json();

          logIn(
            `Bearer ${data["access_token"]}`,
            data["payload"] as UserPayload,
          );
          onSuccess();
        } else {
          const error = await res.json();
          const detail: string = error["detail"];
          // console.log(detail, detail.includes("exist"), Alerts);

          if (detail.includes("exist")) {
            setShowAlert(Alerts.USER_ALREADY_EXISTS);
          } else {
            setShowAlert(Alerts.SERVER_ERROR);
          }
        }
      } catch (e) {
        setShowAlert(Alerts.SERVER_ERROR);
      }
    };

    registerUser();
  };

  return (
    <div>
      <p className="text-3xl font-bold text-center">Register</p>
      <p className="mt-2 text-xl text-slate-400 mb-8 text-center">
        Create your account or{" "}
        <button
          onClick={() => changeForm("login")}
          className=" text-gbci-accent font-bold"
        >
          log in
        </button>{" "}
        if you already have an account
      </p>

      <form className="w-full flex flex-col gap-2" onSubmit={handleSubmit}>
        <label className="text-slate-400 font-bold flex flex-col">
          NAME:
          <input
            className="border-2 rounded-lg p-2 w-full"
            placeholder="Name"
            type="text"
            id="name"
            value={formData.name}
            onChange={handleChange}
            autoFocus={true}
          />
          <p
            className={
              formErrors.name ? "text-red-500" : "text-transparent select-none"
            }
          >
            {formErrors.name || "z"}
          </p>
        </label>

        <label className="text-slate-400 font-bold flex flex-col">
          EMAIL:
          <input
            className="border-2 rounded-lg p-2 w-full"
            placeholder="Email address"
            type="text"
            id="email"
            value={formData.email}
            onChange={handleChange}
          />
          <p
            className={
              formErrors.email ? "text-red-500" : "text-transparent select-none"
            }
          >
            {formErrors.email || "z"}
          </p>
        </label>

        <label className="text-slate-400 font-bold flex flex-col">
          PASSWORD:
          <input
            className="border-2 rounded-lg p-2 w-full"
            placeholder="Password"
            type="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
          />
          <p
            className={
              formErrors.password
                ? "text-red-500"
                : "text-transparent select-none"
            }
          >
            {formErrors.password || "z"}
          </p>
        </label>

        <label className="text-slate-400 font-bold flex flex-col">
          CONFIRM PASSWORD:
          <input
            className="border-2 rounded-lg p-2 w-full"
            placeholder="Confirm Password"
            type="password"
            id="confirm_password"
            value={formData.confirm_password}
            onChange={handleChange}
          />
          <p
            className={
              formErrors.confirm_password
                ? "text-red-500"
                : "text-transparent select-none"
            }
          >
            {formErrors.confirm_password || "z"}
          </p>
        </label>

        <Button size="large" type="submit">
          Create account
        </Button>
      </form>

      {showAlert !== Alerts.NO_ALERT && (
        <div className="mt-8 p-4 text-red-400 bg-red-100 border border-red-400 rounded-md">
          <p>
            {showAlert == Alerts.USER_ALREADY_EXISTS
              ? "User already exists. Please log in"
              : "Server error. Please try again"}
          </p>
        </div>
      )}
    </div>
  );
};

export default RegisterForm;
