import { FC, useState } from "react";
import Modal, { ModalProps } from "../common/modal";
import Button from "../common/button";

interface PrivacyPolicyModal extends Pick<ModalProps, "handleClose"> {}

const PrivacyPolicyModal: FC<PrivacyPolicyModal> = ({ handleClose }) => {
  return (
    <Modal handleClose={handleClose} size="large" header="Privacy Policy">
      <section>
        <p className="text-xl mb-2">Effective Date: July 7, 2024</p>
        <p className="text-base mb-4">
          Thank you for visiting the GBCI Group&apos;s website (&quot;we&quot;,
          &quot;our&quot;, &quot;us&quot;). Your privacy is important to us.
          This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you visit our website
          [www.gbcigroup.com], including any other media form, media channel,
          mobile website, or mobile application related or connected to the
          website. Please read this privacy policy carefully. If you do not
          agree with the terms of this privacy policy, please do not access the
          website.
        </p>
        <p className="text-xl mb-2">Information we collect</p>
        <p className="text-base mb-2">
          We may collect information about you in a variety of ways. The
          information we may collect on the website includes:
        </p>
        <p className="text-base mb-2">
          - Personal Data: Personally identifiable information, such as your
          name, business name, business address, email address, and information
          about your business, all of this is information that you voluntarily
          give to us when you choose to participate in various activities
          related to the website, such as contact requests or assessment results
          requests.
        </p>
        <p className="text-base mb-4">
          - Derivative Data: Information our servers automatically collect when
          you access the website, such as your IP address, your browser type,
          your operating system, your access times, and the pages you have
          viewed directly before and after accessing the website.
        </p>

        <p className="text-xl mb-2">Use of your information</p>
        <p className="text-base mb-2">
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience. Specifically, we may use
          information collected about you via the website to:
        </p>
        <p className="text-base mb-2">
          - Compile anonymous statistical data and analysis for use internally
          or with third parties.
        </p>
        <p className="text-base mb-2">
          - Improve the functionality of our website.
        </p>
        <p className="text-base mb-4">
          - Monitor and analyze usage and trends to improve your experience with
          the website.
        </p>

        <p className="text-xl mb-2">Disclosure of your information</p>
        <p className="text-base mb-2">
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <p className="text-base mb-4">
          - By Law or to Protect Rights: If we believe the release of
          information about you is necessary to respond to legal process, to
          investigate or remedy potential violations of our policies, or to
          protect the rights, property, and safety of others, we may share your
          information as permitted or required by any applicable law, rule, or
          regulation.
        </p>

        <p className="text-xl mb-2">Third-party service providers</p>
        <p className="text-base mb-2">
          We may use third-party service providers to service various aspects of
          the website. Each third-party service provider&apos;s use of your
          personal information is dictated by their respective privacy policies.
        </p>
        <p className="text-base mb-2">
          - YouTube Videos: We embed videos from our official YouTube channel
          using YouTube&apos;s privacy-enhanced mode. This mode may set cookies
          on your computer once you click on the YouTube video player, but
          YouTube will not store personally identifiable cookie information for
          playbacks of embedded videos using the privacy-enhanced mode.
        </p>
        <p className="text-base mb-4">
          - LinkedIn Insight Tag: We use the LinkedIn Insight Tag to track
          conversions, retarget website visitors, and gain insights about how
          LinkedIn members use our website. The LinkedIn Insight Tag enables the
          collection of metadata such as IP address information, timestamp, and
          events (e.g., page views). All data is encrypted. LinkedIn does not
          share personal data with the website owner; it only provides
          aggregated reports about website audience and ad performance.
        </p>

        <p className="text-xl mb-2">Security of your information</p>
        <p className="text-base mb-4">
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>

        <p className="text-xl mb-2">Policy for children</p>
        <p className="text-base mb-4">
          We do not knowingly solicit information from or market to children
          under the age of 13. If we learn that we have collected personal
          information from a child under age 13 without verification of parental
          consent, we will delete that information as quickly as possible.
        </p>

        <p className="text-xl mb-2">Changes to this privacy policy</p>
        <p className="text-base mb-4">
          We may update this Privacy Policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons.
        </p>

        <p className="text-xl mb-2">Contact us</p>
        <p className="text-base mb-4">
          If you have questions or comments about this Privacy Policy, please
          contact us at this email: contact@gbcigroup.com. You can also call us
          to our contact telephone: +52 833 160 8894.
        </p>

        <div className="flex justify-center">
          <div>
            <Button onClick={handleClose}>Close</Button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default PrivacyPolicyModal;
